<template>
  <v-app>
    <v-container>
      <!--  -->
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <base-material-card title="Edit Polling">
            <!--  -->
            <v-form @submit.enter.prevent="editPolling">
              <v-card>
                <!--  -->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field
                        v-model="polling.data.id"
                        label="ID"
                        disabled
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!--  -->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field
                        v-model="polling.data.question"
                        label="Pertanyaan"
                        clearable
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!--  -->
                <div
                  v-for="(data, i) in polling.data.options"
                  :key="i"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span
                          class="d-flex justify-end"
                          style="cursor: pointer;"
                          @click="deleteInput(i)"
                        ><v-icon color="red">
                          mdi-close-octagon
                        </v-icon></span>
                        <v-text-field
                          v-model="data.option"
                          :label="'Pilihan Ke' + (i + 1)"
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div class="d-flex justify-end mt-6 mb-5 mr-6">
                  <v-tooltip
                    bottom
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="white"
                        icon
                        v-bind="attrs"
                        style="border-radius: 100%"
                        v-on="on"
                        @click="add"
                      >
                        <v-icon
                          color="primary"
                          medium
                        >
                          mdi-plus-circle-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Tambah</span>
                  </v-tooltip>
                </div>
                <div>
                  <v-list-item>
                    <v-list-item-content>
                      <!--  -->
                      <v-checkbox
                        v-model="polling.data.settings.anonymous_answer"
                        label="Anonymous Answer"
                      />
                    </v-list-item-content>
                    <v-list-item-content
                      class="ml-12 d-flex justify-end mt-6 mb-5 mr-6"
                    >
                      <!--  -->
                      <v-checkbox
                        v-model="polling.data.settings.multiple_answer"
                        label="Multiple Answer"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <!--  -->
              </v-card>
              <div class="d-flex justify-end mt-6 mb-5 mr-6">
                <v-btn
                  color="primary"
                  @click="editPolling"
                >
                  Sunting
                </v-btn>
              </div>
            </v-form>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'EditPolling',
    data () {
      return {
        polling: {
          meta: {},
          data: {
            id: 0,
            question: '',
            options: [
              {
                options: '',
              },
            ],
            new_option: [''],
            settings: {
              anonymous_answer: 0,
              multiple_answer: 0,
              quiz_mode: 0,
            },
          },
        },
      }
    },
    mounted () {
      this.getPolling()
    },
    methods: {
      deleteInput (index) {
        if (index > 0 || this.polling.data.options.length > 1) {
          this.polling.data.options.splice(index, 1)
        }
      },
      add () {
        this.polling.data.options.push({ option: '' })
      },
      setPoling (data) {
        this.polling = data
      },
      getPolling () {
        axios
          .get('/v1/polling/' + this.$route.params.id + '?entities=user,options')
          .then(response => {
            this.setPoling(response.data)
          })
      },
      editPolling () {
        axios
          .patch('/v1/polling/' + this.$route.params.id, {
            question: this.polling.data.question,
            votes: (this.polling.data.votes = 0),
            settings: {
              anonymous_answer: this.polling.data.settings.anonymous_answer,
              multiple_answer: this.polling.data.settings.multiple_answer,
              quiz_mode: 0,
            },
            options: this.polling.data.options,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/polling' })
              this.$toast.success('Berhasil Di Sunting', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              console.log(response.data.meta.message)
            }
          })
        console.log(this.polling.data.settings.anonymous_answer)
      // console.log(this.polling.data.settings.multiple_answer)
      },
    },
  }
</script>

<style></style>
